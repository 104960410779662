import React from "react";
import {Tab, Tabs} from "react-bootstrap";
import Statistics from "./Statistics";
import Missions from "./Missions";

export default function PublicProfile() {
    return (
        <Tabs id="fill-tab-example" defaultActiveKey="profile" className="mb-3" fill>
            <Tab eventKey="profile" title="Profile">
                <Statistics/>
            </Tab>
            <Tab eventKey="missions" title="Missions">
                <Missions/>
            </Tab>
        </Tabs>
    );
}