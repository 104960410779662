import React, {Fragment, useEffect, useState} from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";

export default function Post() {

    const location = useLocation();
    const { idPost } = useParams();

    const [post, setPost] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionId, setSuggestionId] = useState([]);

    useEffect(() => {
        AOS.init({});
        window.scrollTo(0, 0);

        if (post !== undefined) {
            axios({
                method: "get",
                url: process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_POST"],
                params: {
                    idPost: idPost,
                },
            })
                .then(response => response.data)
                .then((data) => {
                    setPost(data);
                }).catch(error => {
                console.log(error);
            });

            axios({
                method: "get",
                url: process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_SUGGESTIONS"],
                params: {
                    idPost: idPost,
                },
            })
                .then(response => response.data)
                .then((data) => {
                    setSuggestions(data);
                }).catch(error => {
                console.log(error);
            });
        }
    }, [location]);

    return (
        <section className="container">
            <div className="row">
                {
                    post.map((post, id) =>
                        <div key={id} className="col-md-8 section-bg mb-5">
                            <div className="post">
                                <img
                                    src={process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_NEWS_PICTURE"] + post.picture}
                                    className="img-fluid mb-3 col-12 post-image" alt="post-picture"/>
                                <h1 className="display-4">{post.title}</h1>
                                <p className="text-muted">Published on {post.createdAt}</p>
                                <hr className="my-4"/>
                                <p className="lead">{post.text.split('\n').map((item, i) => <Fragment key={i}>{item}<br/></Fragment>)}</p>
                            </div>
                        </div>
                    )
                }
                <div className="col-md-4 related-articles post">
                    <h3 className="text-center">More News</h3>
                    {
                        suggestions.map((suggestion, id) =>
                            <div className="col-12 mb-4">
                                <div className="card news-card">
                                    <img
                                        src={process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_NEWS_PICTURE"] + suggestion.picture}
                                        alt={"news-picture" + id} className="post-news"/>
                                    <div className="card-body">
                                        <h5 className="card-title">{suggestion.title}</h5>
                                        <span>{suggestion.createdAt}</span>
                                        <div className="text-end">
                                            <Link to={"/post/" + suggestion.id} className="btn btn-book-a-table bottom-100 button">Read News</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </section>
    );
}