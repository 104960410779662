import React, {useEffect} from "react";
import axios from "axios";
import {useState} from "react";
import Cookies from "universal-cookie";
import {useUpdate} from "../../other/GlobalVariables";
import AOS from "aos";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function LeaderBoard() {

    const cookies = new Cookies();
    const [leaderBoard, setLeaderBoard] = useState([]);
    const [loading, setLoading] = useState(true);
    const [image, setImage] = useState(null);
    const currentUser = cookies.get("idUser");

    const {update} = useUpdate();

    useEffect(() => {
        AOS.init({});

        axios({
            method: "post",
            url: process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_LEADER_BOARD"],
        })
            .then(response => response.data)
            .then((data) => {
                setLeaderBoard(data);
                setLoading(false);
            })
    }, [update]);

    return (
        <div>
            <section id="github" className="chefs section-bg">
                <div className="container aos-init aos-animate" data-aos="fade-up">
                    <Row>
                        {
                            leaderBoard.map((user, id) =>
                                <div key={id} className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                    <div className="user-card card">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center flex-wrap nowrap">
                                                <img src={process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_PROFILE_PICTURE"] + user.picture} alt={user.username + "-profile-picture"} className="rounded-circle me-3 user-image" />
                                                <div>
                                                    <h5 className="card-title mb-0">{user.username}</h5>
                                                    <h6 className="card-title mb-0">Rank {user.rank}</h6>
                                                    <h6 className="card-title completed mb-0">{parseFloat(user.completed)}%</h6>
                                                </div>
                                                <div className="text-end ms-auto">
                                                    {currentUser.toString() !== user.id.toString() ?
                                                        <span><Link to={"../" + user.username.toLowerCase()} className="btn-link button text-decoration-none">View</Link></span> :
                                                        <span></span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </Row>

                </div>
            </section>
        </div>
    );
}