import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import AOS from "aos";
import jwt from 'jwt-decode';

export default function Confirm() {

    const navigate = useNavigate();
    const [error, setError] = useState("Here you can confirm your email account");
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const {token} = useParams();

    useEffect(() => {
        AOS.init({});

        try {
            setName(jwt(token).name);
            setLoading(true);
        } catch (e) {
            setLoading(false);
        }
    }, []);

    const confirm = (event) => {
        event.preventDefault();

        //navigate("/profile");

        axios({
            method: "post",
            url: process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_CONFIRM_EMAIL"],
            params : {
                token: token,
            }
        })
            .then(response => response.data)
            .then((data) => {
                setError(data);
            }).catch(error => {
                console.log(error);
        })
    }

    return (
        <div>
            <section id="forms" className="forms d-flex align-items-center section-bg">
                <div className="container" data-aos="fade-up">
                    <div className="justify-content-between gy-5">
                        <div className="text-center text-lg-start top0">
                            <div className="container contact" data-aos="fade-up">
                                <form method="post" role="form" className="email-form p-3 p-md-4" data-aos="fade-up"
                                      data-aos-anchor-placement="top-bottom" onSubmit={confirm}>
                                    <div className="text-center">
                                        {loading ?
                                            <>
                                                <h2 data-aos="fade-up">Confirm Email</h2>
                                                <h3>Hello {name}</h3>
                                                <p>{error}</p>
                                                <button className="btn btn-primary" type="submit">Confirm</button>
                                            </> :
                                            <>
                                                <h2 data-aos="fade-up">Invalid Token</h2>
                                                <p>Please make sure you have the correct token or have already confirmed it.</p>
                                            </>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}