import React, {useState, useEffect} from "react";
import AOS from "aos";
import axios from "axios";
import {emailRegex} from "../../other/Regex";

export default function ForgotPassword() {

    const [error, setError] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
        AOS.init({});
    }, []);

    const clearError = () => {
        setError("");
    }

    const forgotPassword = (event) => {
        event.preventDefault();

        if (!emailRegex.test(email)) {
            setError("Email address is not valid");
            return;
        }

        axios({
            method: "post",
            url: process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_FORGOT_PASSWORD"],
            params : {
                email: email
            }
        })
            .then(response => response.data)
            .then((data) => {
                setError(data)
            }).catch(error => {
            console.log(error);
        })
    }

    return (
        <div>
            <section id="forms" className="forms d-flex align-items-center text-center section-bg">
                <div className="container" data-aos="fade-up">
                    <div className="row justify-content-between gy-5">
                        <div className="col-lg order-2 order-lg-2 text-center text-lg-start top0">
                            <h2 className="text-center" data-aos="fade-up">Forgot Password</h2>
                            <div className="container contact d-flex justify-content-center" data-aos="fade-up">
                                <form className="email-form p-3 p-md-4 max-w" data-aos="fade-up"
                                      data-aos-anchor-placement="top-bottom" onSubmit={forgotPassword}>
                                    <h3 id="error-label" className="text-center">{error}</h3>
                                    <div className="form-group">
                                        <input id="email" type="text" name="email" className="form-control mt-1"
                                               placeholder="Enter email"
                                               value={email}
                                               onChange={(event) => setEmail(event.target.value)} onClick={clearError}/>
                                    </div>
                                    <div className="text-center">
                                        <button className="btn btn-primary" type="submit">Send Email</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}