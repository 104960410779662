import React, {useEffect} from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import {useState} from "react";
import axios from "axios";
import Cookies from "universal-cookie";

export default function Search() {

    const cookies = new Cookies();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const currentUser = cookies.get("idUser");

    const location = useLocation();
    const { username } = useParams();

    useEffect(() => {
        if (!(username === undefined || username === "")) {
            axios({
                method: "post",
                url: process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_SEARCH_USERS"],
                params: {username: username}
            })
                .then(response => response.data)
                .then((data) => {
                    setUsers(data);
                    setLoading(true);
                })
        }
    }, [location]);

    return (
        <div>
            {
                loading ?
                    <section id="github" className="chefs section-bg">
                        <div className="container aos-init aos-animate" data-aos="fade-up">
                            {
                                users.length !== 0 ?
                                    <div>
                                        {users.length === 1 ?
                                            <h2 className="text-center">Search Result</h2> :
                                            <h2 className="text-center">Search Results</h2>
                                        }
                                        <div className="row">
                                            {users.map((user, id) =>
                                                <div key={id} className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                                    <div className="user-card card">
                                                        <div className="card-body">
                                                            <div className="d-flex align-items-center flex-wrap nowrap">
                                                                <img
                                                                    src={process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_PROFILE_PICTURE"] + user.picture}
                                                                    alt={user.username + "-profile-picture"}
                                                                    className="rounded-circle me-3 user-image"/>
                                                                <div>
                                                                    <h5 className="card-title mb-0">{user.username}</h5>
                                                                    <h6 className="card-title mb-0">Rank {user.rank}</h6>
                                                                </div>
                                                                <div className="text-end ms-auto">
                                                                    {currentUser !== user.id.toString() ?
                                                                        <Link to={"../" + user.username.toLowerCase()}
                                                                              className="btn-link button link-decoration">View</Link> :
                                                                        <Link to={"../profile"}
                                                                              className="btn-link button link-decoration">View</Link>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div> :
                                    <div className="text-center">
                                        <h1>Sorry, we didnt find any users with this username</h1>
                                    </div>
                            }
                        </div>
                    </section> :
                    <div className="text-center">
                        <h1>Loading</h1>
                    </div>
            }
        </div>
    );
}