import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import {useState} from "react";
import Footer from "../Footer/Footer";

export default function News() {

    const [news, setNews] = useState([]);

    useEffect(() => {
            AOS.init({});
            window.scrollTo(0, 0);

            axios({
                method: "get",
                url: process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_NEWS"],
                params: {},
            })
                .then(response => response.data)
                .then((data) => {
                    setNews(data);
                }).catch(error => {
                    console.log(error);
            });
        }, []);

    return (
        <div className="section-bg">
            <section className="container news">
                <h2 className="news-title" data-aos="fade-up">News</h2>
                <div className="row news post">
                    {
                        news.map((news, id) =>
                            <div key={id} className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                <div className="card news-card">
                                    <img src={process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_NEWS_PICTURE"] + news.picture} alt={"news-picture" + id} className=""/>
                                    <div className="card-body">
                                        <h5 className="card-title">{news.title}</h5>
                                        <span>{news.createdAt}</span>
                                        <div className="text-end">
                                            <Link to={"/post/" + news.id} className="btn bottom-100 button">Read News</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </section>
            <Footer/>
        </div>
    );
}