import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Cookies from "universal-cookie";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../Footer/Footer";
import {useState} from "react";
import {allowedCharactersForName, emailRegex} from "../../other/Regex";
import {IconContext} from "react-icons";
import {GoLocation} from "react-icons/go";
import {BsClock, BsTelephone} from "react-icons/bs";
import {HiOutlineMail} from "react-icons/hi";
import axios from "axios";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

export default function Index() {

    const navigate = useNavigate();
    const cookies = new Cookies();
    const checkCookie = cookies.get("idUser");
    const history = createBrowserHistory();

    const [error, setError] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        if (checkCookie) {
            navigate("/profile");
        }

        AOS.init({});
        //window.scrollTo(0, 0);

        ReactGA.pageview(window.location.pathname + window.location.search);

        window.gtag('config', 'G-SSGZDJE74V', {
            page_path: window.location.pathname,
        });

    }, []);

    const clearError = () => {
        setError("");
    }

    const contact = (event) => {
        event.preventDefault();

        if (name.trim().length === 0 || email.trim().length === 0 || subject.trim().length === 0 || message.trim().length === 0) {
            setError("Fields can not be empty");
            return;
        }

        if (!allowedCharactersForName.test(name)) {
            setError("Illegal characters in full name");
            return;
        }

        if (!emailRegex.test(email)) {
            setError("Email address is not valid");
            return;
        }

        axios({
            method: "post",
            url: process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_CONTACT"],
            params: {
                name: name,
                email: email,
                subject: subject,
                message: message
            }
        })
            .then(response => response.data)
            .then((data) => {
                setError(data);
            }).catch((error) => {
            console.log(error);
        })
    }

    const changeUrl = () => {
        history.push('/');
    }

   /*const getOs = () => {

       const userAgent = navigator.userAgent || navigator.vendor;

       switch (true) {
           case (/Windows/.test(userAgent)):
               alert("Windows");
               break;
           case (/Android/.test(userAgent)):
               alert("Android");
               break;
           case (/iPhone/.test(userAgent)):
               alert("iPhone");
               break;
           case (/Linux/.test(userAgent)):
               alert("Linux");
               break;
           default:
               alert("No Match");
       }
   }*/

    return (
        <div>
            <section id="forms" className="forms d-flex align-items-center section-bg">
                <div className="container" data-aos="fade-up">
                    <div className="row justify-content-between gy-5">
                        <h2 data-aos="fade-up" className="text-center">Memo Stick Rescue</h2>
                        <div className="col-lg-7 order-1 order-lg-1 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start">
                            <p data-aos="fade-up" data-aos-delay="100">
                                <video className="img-fluid" controls autoPlay={false} loop={true}>
                                    <source src={process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_VIDEO"] + "trailer-video.mp4"} type="video/mp4"/>
                                    <track src="../captions/En.vtt" kind="captions" srcLang="en" label="english_captions"/>
                                </video>
                            </p>
                        </div>
                        <div className="col-lg-5 order-2 order-lg-2 text-center text-lg-start">
                            <h3 data-aos="fade-up">About</h3>
                            Memo Stick Rescue, provides a dramatic experience like no other.
                            In Memo Stick Rescue, you can fight enemies while trying to make your way to the end of the current game map.
                            Do more and be more by playing to your strengths and creating your own path to victory.
                            Immerse yourself in the glorious chaos of all-out fun
                            and tactics only available in Memo Stick Rescue.
                            <div className="text-center post" data-aos="fade-up" data-aos-delay="200">
                                <a href={"#download"} className="more-btn">
                                    <button type="button" value="Download" className="btn button" onClick={changeUrl()}>Download</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="download" className="box">
                <div className="container" data-aos="fade-up">

                    <div className="section-header">
                        <h2>Download</h2>
                        <p>Memo Stick Rescue</p>
                    </div>

                    <div className="row">

                        <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                            <div className="box-section">
                                <h3>System requirements</h3>
                                <p><b>OS:</b> Windows 7</p>
                                <p><b>Processor (AMD):</b> Athlon X2 2.2 GHz</p>
                                <p><b>Processor (Intel):</b> Core 2 Duo 2.4 GHz</p>
                                <p><b>Memory: </b> 512MB</p>
                                <p><b>Hard Drive:</b> 100MB</p>
                                <p><b>Graphics card (AMD):</b> AMD Radeon HD 3870</p>
                                <p><b>Graphics card (NVIDIA):</b> Nvidia GeForce 8600</p>
                                <p><b>Graphics memory:</b> 64MB</p>
                            </div>
                        </div>

                        <div className="col-lg-8 col-12 d-flex justify-content-center align-items-center">
                            <div className="row col-12">
                                <div className="col-sm-4 col-4" data-aos="fade-up" data-aos-delay="200">
                                    <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                        <img src={process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_OTHER_PICTURE"] + "windows-icon.png"} alt="windows-icon" className="us-image"/>
                                        <h4>Windows</h4>
                                        <p>EXE file</p>
                                        <div className="text-center">
                                            <a href={process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_BINARIES"] + "Memo Stick Rescue.msi"} className="more-btn">Download</a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4" data-aos="fade-up" data-aos-delay="300">
                                    <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                        <img src={process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_OTHER_PICTURE"] + "linux-icon.png"} alt="linux-icon" className="us-image"/>
                                        <h4>Linux</h4>
                                        <p>ZIP file</p>
                                        <div className="text-center">
                                            <a href={process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_BINARIES"] + "Memo Stick Rescue.zip"} className="more-btn">Download</a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4" data-aos="fade-up" data-aos-delay="400">
                                    <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                        <img src={process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_OTHER_PICTURE"] + "android-icon.png"} alt="android-icon" className="us-image"/>
                                        <h4>Android</h4>
                                        <p>APK file</p>
                                        <div className="text-center">
                                            <a className="more-btn">Coming Soon</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id="github" className="source section-bg">
                <div className="container" data-aos="fade-up">

                    <div className="section-header">
                        <h2>GitHub</h2>
                        <p>Source code</p>
                    </div>

                    <div className="row gy-4">

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up"
                             data-aos-delay="100">
                            <div className="source-member">
                                <div className="member-img">
                                    <img src={process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_OTHER_PICTURE"] + "github-icon.png"} className="img-fluid download-image" alt="github-icon"/>
                                </div>
                                <div className="member-info">
                                    <h2>Video game</h2>
                                    <span>Java - LibGDX</span>
                                    <p>This code gives us insight into the game itself, in which everything takes place. From the store where you can buy different skins, to the game itself, which you can play</p>
                                    <a href="https://github.com/Mister-3551/MemoStickRescue" target="_blank" className="btn-book-a-table">View Code</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up"
                             data-aos-delay="200">
                            <div className="source-member">
                                <div className="member-img">
                                    <img src={process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_OTHER_PICTURE"] + "github-icon.png"} className="img-fluid download-image" alt="github-icon"/>
                                </div>
                                <div className="member-info">
                                    <h2>Web application</h2>
                                    <span>JavaScript - React</span>
                                    <p>This code provides insight into the development process of a web application designed to view your stats and stats from any player</p>
                                    <a href="https://github.com/Mister-3551/Frontend" target="_blank" className="btn-book-a-table">View Code</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up"
                             data-aos-delay="300">
                            <div className="source-member">
                                <div className="member-img">
                                    <img src={process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_OTHER_PICTURE"] + "github-icon.png"} className="img-fluid download-image" alt="github-icon"/>
                                </div>
                                <div className="member-info">
                                    <h2>Backend</h2>
                                    <span>Java - Spring Boot</span>
                                    <p>This code gives us insight into the very background and logic of the so-called backend, which is intended for data calculation</p>
                                    <a href="https://github.com/Mister-3551/Backend" target="_blank" className="btn-book-a-table">View Code</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="contact" className="contact">
                <div className="container" data-aos="fade-up">

                    <div className="section-header">
                        <h2>Contact</h2>
                        <p>Need Help? <span>Contact Us</span></p>
                    </div>

                    <div className="mb-3">
                        <iframe title = "presentation-video" src = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2781.8550287859675!2d15.156853615827417!3d45.79413201938775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4764ffecf5ac734f%3A0x24c2191f1950c228!2s%C5%A0olski%20center%20Novo%20mesto!5e0!3m2!1ssl!2ssi!4v1676923848476!5m2!1ssl!2ssi"
                            allowFullScreen = "" loading = "lazy"
                            referrerPolicy = "no-referrer-when-downgrade" className = "google-maps"></iframe>
                    </div>

                    <div className="row gy-4" data-aos="fade-up">

                        <div className="col-md-6">
                            <div className="info-item  d-flex align-items-center">
                                <IconContext.Provider value={{ color: "white", size: "30px" }}>
                                    <GoLocation className="icon bi"/>
                                </IconContext.Provider>
                                <div>
                                    <h3>Our Address</h3>
                                    <p>School center of Novo mesto</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="info-item d-flex align-items-center">
                                <IconContext.Provider value={{ color: "white", size: "30px" }}>
                                    <HiOutlineMail className="icon bi"/>
                                </IconContext.Provider>
                                <div>
                                    <h3>Email Us</h3>
                                    <p><a href="mailto:info@memostickrescue.eu.org" className="text-dark">info@memostickrescue.eu.org</a></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="info-item  d-flex align-items-center">
                                <IconContext.Provider value={{ color: "white", size: "30px" }}>
                                    <BsTelephone className="icon bi"/>
                                </IconContext.Provider>
                                <div>
                                    <h3>Call Us</h3>
                                    <p>Not available</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="info-item  d-flex align-items-center">
                                <IconContext.Provider value={{ color: "white", size: "30px" }}>
                                    <BsClock className="icon bi"/>
                                </IconContext.Provider>
                                <div>
                                    <h3>Opening Hours</h3>
                                    <div>
                                        <b>Mon-Sat:</b> 11AM - 11PM <br/>
                                        <b>Sunday:</b> Closed
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <form role="form" className="email-form p-3 p-md-4" data-aos="fade-up"
                          data-aos-anchor-placement="top-bottom" onSubmit={contact}>
                        <h3 id="error-label" className="text-center">{error}</h3>
                        <div className="form-group">
                            <input type="text" name="name" className="form-control" id="name"
                                   placeholder="Your Full Name" onChange={(event) => setName(event.target.value)} onClick={clearError}/>
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" name="email" id="email"
                                   placeholder="Your Email" onChange={(event) => setEmail(event.target.value)} onClick={clearError}/>
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" name="subject" id="subject"
                                   placeholder="Subject" onChange={(event) => setSubject(event.target.value)} onClick={clearError}/>
                        </div>
                        <div className="form-group">
                            <textarea className="form-control" name="message" rows="5" placeholder="Message" onChange={(event) => setMessage(event.target.value)} onClick={clearError}/>
                        </div>
                        <div className="text-center">
                            <button type="submit">Send Message</button>
                        </div>
                    </form>
                </div>
            </section>

           <Footer/>
        </div>
    )
};