import Cookies from "universal-cookie";

export default function setCookie(name, value, days) {
    const cookies = new Cookies();
    const date = new Date();

    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    cookies.set(name, value, {
        path: '/',
        expires: new Date(date),
    });
    return true;
}