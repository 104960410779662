import React from "react";
import {IconContext} from "react-icons";
import {BsFacebook, BsInstagram, BsLinkedin, BsTwitter} from "react-icons/bs";

export default function Footer() {
    return (
        <footer id="footer" className="footer container-fluid">

            <div className="container">
                <div className="row gy-3">
                    <div className="col-lg-3 col-md-6 d-flex">
                        <i className="bi bi-geo-alt icon"></i>
                        <div>
                            <h4>Address</h4>
                            <p>School center of Novo mesto<br/></p>
                        </div>

                    </div>

                    <div className="col-lg-3 col-md-6 footer-links d-flex">
                        <i className="bi bi-telephone icon"></i>
                        <div>
                            <h4>Contact</h4>
                            <p>
                                <b>Phone:</b> Not Available<br/>
                                <b>Email:</b> info@memostickrescue.eu.org<br/>
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 footer-links d-flex">
                        <i className="bi bi-clock icon"></i>
                        <div>
                            <h4>Opening Hours</h4>
                            <p>
                                <b>Mon-Sat: 11AM - 11PM</b><br/>
                                Sunday: Closed
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 footer-links">
                        <h4>Follow Us</h4>
                        <div className="social-links d-flex">
                            <a href="../" className="twitter" target="_blank" aria-label="Find us on Twitter">
                                <IconContext.Provider value={{ color: "white" }}>
                                    <BsTwitter/>
                                </IconContext.Provider>
                            </a>
                            <a href="../" className="facebook" target="_blank" aria-label="Find us on Facebook">
                                <IconContext.Provider value={{ color: "white"}}>
                                    <BsFacebook/>
                                </IconContext.Provider>
                            </a>
                            <a href="../" className="instagram" target="_blank" aria-label="Find us on Instagram">
                                <IconContext.Provider value={{ color: "white"}}>
                                    <BsInstagram/>
                                </IconContext.Provider>
                            </a>
                            <a href="../" className="linkedin" target="_blank" aria-label="Find us on Linkedin">
                                <IconContext.Provider value={{ color: "white"}}>
                                    <BsLinkedin/>
                                </IconContext.Provider>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="copyright">
                    &copy; Copyright <strong><span>Memo Stick Rescue</span></strong>. All Rights Reserved
                </div>
            </div>
        </footer>
    );
}