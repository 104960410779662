import React from "react";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import "aos/dist/aos.css";
import {useEffect} from "react";
import AOS from "aos";
import button from "bootstrap/js/src/button";
import {
    allowedCharactersForName,
    allowedCharactersForPassword,
    allowedCharactersForUsername,
    emailRegex, specialCharacters, upperCase
} from "../../other/Regex";
import Country from "./Country";

export default function SignUp() {

    const cookies = new Cookies();
    const checkCookie = cookies.get("idUser");
    const navigate = useNavigate();

    const [error, setError] = useState("");
    const [fullName, setFullName] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [country, setCountry] = useState("");

    useEffect(() => {

        if (checkCookie) {
            navigate("/profile");
        }

        AOS.init({});
        window.scrollTo(0, 0);
    }, []);

    const clearError = () => {
        setError("");
    }

    const signUp = (event) => {
        event.preventDefault();

        if (fullName.trim().length === 0 || username.trim().length === 0 || email.trim().length === 0 || password.trim().length === 0 || country.trim().length === 0) {
            setError("Fields can not be empty");
            return;
        }

        if (username.length > 15 || username.length < 4) {
            setError("Username length must be between 4 - 15 characters");
            return;
        }

        if (!allowedCharactersForName.test(fullName)) {
            setError("Illegal characters in full name");
            return;
        }

        if (!allowedCharactersForUsername.test(username)) {
            setError("Illegal characters in username");
            return;
        }

        if (!emailRegex.test(email)) {
            setError("Email address is not valid");
            return;
        }

        if (password.length < 8 || password.length > 16) {
            setError("Password length must be between 8 and 16 characters");
            return;
        }

        if (!upperCase.test(password)) {
            setError("Password must contain at least one uppercase letter");
            return;
        }

        if (!specialCharacters.test(password)) {
            setError("Password must contain at least one special character");
            return;
        }

        axios({
            method: "post",
            url: process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_SIGN_UP"],
            params : {
                name: fullName,
                username: username,
                email: email,
                password: password,
                country: country
            }
        })
            .then(response => response.data)
            .then((data) => {
                setError(data)
            }).catch(error => {
            console.log(error);
        })
    };

    return (
        <div>
            <section id="hero" className="forms d-flex align-items-center section-bg">
                <div className="container" data-aos="fade-up">
                    <div className="row justify-content-between gy-5">
                        <div className="col-lg-5 order-1 order-lg-1 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start">
                            <h2 data-aos="fade-up">Sign Up</h2>
                            <p data-aos="fade-up" data-aos-delay="100">Sign up to the application with your email <br/>Get access to view your game statistics and statistics of other players</p>
                        </div>
                        <div className="col-lg-5 order-2 order-lg-2 text-center text-lg-start top0">
                            <div className="container contact" data-aos="fade-up">
                                <form method="post" role="form" className="email-form p-3 p-md-4" data-aos="fade-up"
                                      data-aos-anchor-placement="top-bottom" onSubmit={signUp}>
                                    <h3 id="error-label" className="text-center">{error}</h3>
                                    <div className="form-group">
                                        <input type="text" className="form-control mt-1" name="fullName"
                                               placeholder="Enter your full name"
                                               onChange={(event) => setFullName(event.target.value)} onClick={clearError}/>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control mt-1" name="username"
                                               placeholder="Enter your username"
                                               onChange={(event) => setUsername(event.target.value)} onClick={clearError}/>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control mt-1" name="email"
                                               placeholder="Enter your email address"
                                               onChange={(event) => setEmail(event.target.value)} onClick={clearError}/>
                                    </div>
                                    <div className="form-group">
                                        <input type="password" className="form-control mt-1" name="password"
                                               placeholder="Enter password"
                                               onChange={(event) => setPassword(event.target.value)} onClick={clearError}/>
                                    </div>
                                    <div className="form-group">
                                        <select id="country" name="country" className="form-control mt-1" onChange={(event) => setCountry(event.target.value)} onClick={clearError}>
                                            <Country/>
                                        </select>
                                    </div>
                                    <div className="text-center">
                                        <button className="btn btn-primary" type="submit">Sign Up</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}