import React, {useState, useEffect} from "react";
import {Container, Nav, Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "universal-cookie";
import setCookie from "../../other/Cookies";
import ReactGA from "react-ga";

export default function Navigation() {

    const [showCookie, setShowCookie] = useState(false);
    const [customizeCookie, setCustomizeCookie] = useState(false);
    const [otherCookies, setOtherCookies] = useState(false);
    const cookies = new Cookies();
    const checkCookie = cookies.get("cookie");

    useEffect(() => {
        if (!checkCookie) {
            setShowCookie(true);
        }
    }, []);

    const confirm = () => {
        setCookie("cookie", "agree", 10);
        setGoogleAnalytics();
        setShowCookie(false);
    }

    const customize = () => {
        setShowCookie(false);
        setCustomizeCookie(true);
    }

    const acceptSelected = () => {
        setCookie("cookie", "agree", 10);
        setCustomizeCookie(false);

        if (otherCookies) {
            setGoogleAnalytics();
        }
    }

    const setOtherCookiesBox = (event) => {
        setOtherCookies(event.target.checked);
    }

    const setGoogleAnalytics = () => {
        const TRACKING_ID = "G-SSGZDJE74V";
        ReactGA.initialize(TRACKING_ID);
    }

    return (
        <>
            <Navbar bg="light" expand="sm" className="sticky-top">
                <Container fluid>
                    <Link to={"/"} className="navbar-brand">Memo Stick Rescue</Link>
                    <Navbar.Toggle aria-controls="navbarScroll"/>
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="me-auto my-2 my-lg-0" navbarScroll>
                            <Link to={"news"} className="nav-link">News</Link>
                        </Nav>
                        <Nav className="d-flex">
                            <Link to={"signin"} className="nav-link">Sign In</Link>
                            <Link to={"signup"} className="nav-link">Sign Up</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {
                showCookie ?
                    <div className="fixed-bottom bg-dark p-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <p className="text-white">
                                        We use cookies to improve your browsing experience on our website. Cookies help us
                                        to remember your preferences and to personalize your experience by showing you
                                        relevant content and ads. We also use cookies to analyze website traffic and to
                                        understand how our visitors interact with our site, so we can make improvements and
                                        provide a better service to you.
                                        By continuing to use our website, you are agreeing to our use of cookies. If you
                                        wish to disable cookies, you can do so in your browser settings, but please note
                                        that this may affect the functionality of our website.
                                        &#160;<a href="./privacyPolicy.html" target="_blank" className="cookies-link">Learn
                                        more</a></p>
                                </div>
                                <div className="col-md-4 text-center post">
                                    <button type="button" className="btn btn-primary button mt-2" data-bs-dismiss="alert"
                                            aria-label="Close" onClick={confirm}>Accept
                                    </button>
                                    <br/>
                                    <button type="button" className="btn btn-primary button mt-2" data-bs-dismiss="alert"
                                            aria-label="Close" onClick={customize}>Customize
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> : null
            }
            {
                customizeCookie ?
                    <div className="fixed-bottom bg-dark p-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="btn btn-secondary active disabled mb-2">
                                        <input type="checkbox" name="options" autoComplete="off" checked disabled/> Necessary cookies
                                        for the operation of the web application and the provision of user experience
                                    </label>
                                    <br/>
                                    <label className="btn btn-secondary mb-2">
                                        <input type="checkbox" name="options" onChange={setOtherCookiesBox}/> Cookies for viewing
                                        advanced advertising and better performance
                                    </label>
                                </div>
                                <div className="col-md-4 text-center post">
                                    <button type="button" className="btn btn-primary button mt-2" data-bs-dismiss="alert"
                                            aria-label="Close" onClick={acceptSelected}>Accept Selected
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> : null
            }
        </>
    );
}