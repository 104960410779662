import React, {useEffect} from "react";
import 'react-circular-progressbar/dist/styles.css';
import axios from "axios";
import Cookies from 'universal-cookie';
import {useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {useUpdate} from "../../other/GlobalVariables";
import {Button, Modal} from "react-bootstrap";

export default function Statistics() {

    const cookies = new Cookies();
    const [userData, setUserData] = useState([]);
    const [followersOrFollowing, setFollowersOrFollowing] = useState([]);
    const [weaponsStatistics, setWeaponsStatistics] = useState([]);

    const [loading, setLoading] = useState(true);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [loadingWeapons, setLoadingWeapons] = useState(true);

    const [buttonText, setButtonText] = useState("");

    const [image, setImage] = useState(null);

    const location = useLocation();
    const { username } = useParams();

    const idUserOrUsername = username === undefined || username === null ? cookies.get("idUser") : username;

    const {update} = useUpdate();

    const [show, setShow] = useState(false);

    const [weaponsShow, setWeaponsShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleWeaponClose = () => setWeaponsShow(false);

    const [typeName, setTypeName] = useState([]);

    const currentUser = cookies.get("idUser");

    const navigate = useNavigate();

    useEffect(() => {

        axios({
            method: "post",
            /*headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "authenticate": "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cmJhbjQiLCJleHAiOjE2Nzk4MzUyOTIsImlhdCI6MTY3OTgzMTY5Mn0.2nUGgy72BPGuAji5OdaMVg8QkVI3GKwOKQ5r64iyRBU"
            },*/
            url: process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_PLAYER_STATISTICS"],
            params: {
                idUserOrUsername: idUserOrUsername
            }
        })
            .then(response => response.data)
            .then((data) => {
                setUserData(data);
                const image = update === false ? process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_PROFILE_PICTURE"] + data.picture : process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_PROFILE_PICTURE_UPDATE"] + data.picture;
                setImage(image);
                setLoading(false);
            }).catch(error => {
            console.log(error);
        })

        if (username !== undefined) {
            axios({
                method: "post",
                url: process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_IS_FOLLOW"],
                params: {idUser: cookies.get("idUser"), username: username}
            }).then(response => response.data)
                .then((data) => {
                    setButtonText(data);
                }).catch(error => {
                console.log(error);
            })
        }

    }, [update, location]);

    const updateStatus = (type) => {
        axios({
            method: "post",
            url: process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_SET_IS_FOLLOW"],
            params: {
                idUser: cookies.get("idUser"),
                username: username, type: type
            }
        })
            .then(response => response.data)
            .then((data) => {
                setButtonText(data);
            }).catch(error => {
            console.log(error);
        })
    }

    const openUsers = (type) => {

        const lastPart = type.match("Followers") ? process.env["REACT_APP_FOLLOWERS"] : process.env["REACT_APP_FOLLOWING"];
        const url = process.env["REACT_APP_BACKEND_URL_API"] + lastPart;

        setLoadingUsers(true);

        axios({
            method: "post",
            url: url,
            params: {idUserOrUsername : idUserOrUsername}
        })
            .then(response => response.data)
            .then((data) => {
                setFollowersOrFollowing(data);
                setLoadingUsers(false);
            }).catch(error => {
                console.log(error);
            })

        setTypeName(type);
        setShow(true);

        setFollowersOrFollowing([]);
    }

    const openWeapons = () => {

        setLoadingWeapons(false);

        axios({
            method: "post",
            url: process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_GET_WEAPONS_STATISTICS"],
            params: {idUserOrUsername : idUserOrUsername}
        })
            .then(response => response.data)
            .then((data) => {
                setWeaponsStatistics(data);
                setLoadingWeapons(false);
            }).catch(error => {
            console.log(error);
        })

        setWeaponsShow(true);
        setTypeName("Weapon Statistics");
    }

        return (
            <div>
                {
                    !loading && userData.username !== undefined ?
                        <section id="profile" className="section-bg">
                            <div className="container profile aos-init aos-animate" data-aos="fade-up">
                                <div className="card">
                                    <header className="card-header">
                                        <div className="hello">
                                            <img src={image} alt="profile picture"/>
                                            <div className="heading-box">
                                                <h1>{userData.username}</h1>
                                                <h3><i className="material-icons">Rank {userData.rank}</i></h3>
                                            </div>
                                        </div>
                                        {
                                            parseInt(userData.id) !== parseInt(currentUser) ?
                                            <div className="button-box">
                                                {
                                                    username !== undefined ?
                                                        <div id="ch-btn" className="text-end statistics-button post">
                                                            {buttonText.match("Following") ?
                                                                <button id="btn-wt" className="btn btn-primary button"
                                                                        onClick={() => {
                                                                            updateStatus("Following");
                                                                        }}>{buttonText}</button> :
                                                                <button id="btn-wt" className="btn btn-primary button"
                                                                        onClick={() => {
                                                                            updateStatus("Follow")
                                                                        }}>{buttonText}</button>
                                                            }
                                                        </div> : null
                                                }
                                            </div> : null
                                        }
                                    </header>

                                    <div className="main">
                                        <main className="card-main">
                                            <div className="activity">
                                                <span className="activity-name">{userData.currentXp} / {userData.nextLevelXp} XP</span>
                                            </div>
                                            <div className="activity" onClick={event => openUsers("Followers")}>
                                                <span className="activity-name">{userData.followers} Followers</span>
                                            </div>
                                            <div className="activity" onClick={event => openUsers("Following")}>
                                                <span className="activity-name">{userData.following} Following</span>
                                            </div>
                                        </main>

                                        <main className="card-main">
                                            <div className="activity" onClick={event => openWeapons("Following")}>
                                                <span className="activity-name">Weapons</span>
                                            </div>
                                            <div className="activity">
                                                <span className="activity-name">
                                                    {parseFloat(userData.completed)}% Completed
                                                </span>
                                            </div>
                                            <div className="activity">
                                                <span className="activity-name">{userData.hours}h {userData.minutes}m Played</span>
                                            </div>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </section> :
                        <div className="text-center">
                            <h1>Loading</h1>
                        </div>
                }

                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>{typeName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <section id="hero" className="d-flex align-items-start section-bg">
                            <div className="container mission-panel" data-aos="fade-up">
                                {!loadingUsers ?
                                    <div className="row justify-content-between gy-5">
                                        {followersOrFollowing.length !== 0 ?
                                            <div
                                                className="col-lg-12 justify-content-center order-2 order-lg-1 d-flex flex-column justify-content-center align-items-lg-start text-lg-start">
                                                {
                                                    followersOrFollowing.map((user, id) =>
                                                        <div key={id} className="col-12 mb-4">
                                                            <div className="user-card card">
                                                                <div className="card-body">
                                                                    <div
                                                                        className="d-flex align-items-center flex-wrap nowrap">
                                                                        <img
                                                                            src={process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_PROFILE_PICTURE"] + user.picture}
                                                                            alt={user.username + "-profile-picture"}
                                                                            className="rounded-circle me-3 user-image"/>
                                                                        <div>
                                                                            <h5 className="card-title mb-0">{user.username}</h5>
                                                                            <h6 className="card-title mb-0">Rank {user.rank}</h6>
                                                                        </div>
                                                                        <div className="text-end ms-auto">
                                                                            {currentUser.toString() !== user.id.toString() ?
                                                                                <span><Link to={"../" + user.username.toLowerCase()} className="btn-link button link-decoration">View</Link></span> :
                                                                                <span></span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                            </div> :
                                            <div className="text-center">
                                                <h1>No Users</h1>
                                            </div>
                                        }
                                    </div> :
                                    <div className="text-center">
                                        <h1>Loading</h1>
                                    </div>
                                }
                            </div>
                        </section>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="post">
                            <Button variant="secondary" className="button" onClick={handleClose}>Close</Button>
                        </div>
                    </Modal.Footer>
                </Modal>

                <Modal show={weaponsShow} onHide={handleWeaponClose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>{typeName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <section id="hero" className="d-flex align-items-start section-bg">
                            <div className="container mission-panel" data-aos="fade-up">
                                {!loadingWeapons ?
                                    <div className="row justify-content-between gy-5">
                                        {weaponsStatistics.length !== 0 ?
                                            <div
                                                className="col-lg-12 justify-content-center order-2 order-lg-1 d-flex flex-column justify-content-center align-items-lg-start text-lg-start">
                                                {
                                                    weaponsStatistics.map((weapon, id) =>
                                                        <div key={id} className="col-12 mb-4">
                                                            <div className="user-card card">
                                                                <div className="card-body">
                                                                    <div
                                                                        className="d-flex align-items-center flex-wrap nowrap">
                                                                        <img
                                                                            src={process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_WEAPON_PICTURE"] + weapon.picture.split("-")[1] + "/" + weapon.picture + "-stand.png"}
                                                                            alt={"-weapon-picture"}
                                                                            className="me-3 user-image"/>
                                                                        <div>
                                                                            <h5 className="card-title mb-0">{weapon.name}</h5>
                                                                            <h6 className="card-title mb-0">Kills: {weapon.kills}</h6>
                                                                        </div>
                                                                        <div className="text-end ms-auto">
                                                                            <h6 className="card-title mb-0">Delay: {weapon.speed} s</h6>
                                                                            <h6 className="card-title mb-0">Price: {weapon.price} €</h6>
                                                                            <h6 className="card-title mb-0">Owned: {weapon.owned === 1 ? "True" : "False"}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                            </div> :
                                            <div className="text-center">
                                                <h1>No Weapons</h1>
                                            </div>
                                        }
                                    </div> :
                                    <div className="text-center">
                                        <h1>Loading</h1>
                                    </div>
                                }
                            </div>
                        </section>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="post">
                            <Button variant="secondary" className="button" onClick={handleWeaponClose}>Close</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
}