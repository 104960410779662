import React, {useState, useEffect} from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import AOS from "aos";
import "aos/dist/aos.css";
import jwt from "jwt-decode";
import {useParams} from "react-router-dom";
import {specialCharacters, upperCase} from "../../other/Regex";

export default function NewPassword() {

    const [error, setError] = useState("");

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const { token } = useParams();

    useEffect(() => {
        AOS.init({});
    }, [])

    const clearInputs = () => {
        setNewPassword("");
        setConfirmPassword("");
    }

    const clearError = () => {
        setError("");
    }

    const updatePassword = (event) => {
        event.preventDefault();


        if (newPassword.length < 8 || newPassword.length > 16) {
            setError("Password length must be between 8 and 16 characters");
            return;
        }

        if (!upperCase.test(newPassword)) {
            setError("Password must contain at least one uppercase letter");
            return;
        }

        if (!specialCharacters.test(newPassword)) {
            setError("Password must contain at least one special character");
            return;
        }

        axios({
            method: "post",
            url: process.env["REACT_APP_BACKEND_URL_API"] + process.env["REACT_APP_NEW_PASSWORD"],
            params : {
                email: jwt(token).email,
                newPassword: newPassword,
                confirmPassword:  confirmPassword
            }
        })
            .then(response => response.data)
            .then((data) => {
                if (data === "Password successfully updated") {
                    setError(data);
                    clearInputs();
                } else setError(data)
            }).catch(error => {
            console.log(error);
        })
    }

    return (
        <div>
            <section id="hero" className="forms d-flex align-items-center text-center section-bg">
                <div className="container" data-aos="fade-up">
                    <div className="row justify-content-between gy-5">
                        <div className="col-lg order-2 order-lg-2 text-center top0">
                            <h2 data-aos="fade-up">Change Password</h2>
                            <div className="container contact d-flex justify-content-center" data-aos="fade-up">
                                <form className="email-form p-3 p-md-4 max-w" data-aos="fade-up"
                                      data-aos-anchor-placement="top-bottom" onSubmit={updatePassword}>
                                    <h3 id="error-label" className="text-center">{error}</h3>
                                    <div className="form-group">
                                        <input type="password" className="form-control mt-1"
                                               placeholder="Enter Password"
                                               value={newPassword}
                                               onChange={(event) => setNewPassword(event.target.value)} onClick={clearError}/>
                                    </div>
                                    <div className="form-group">
                                        <input type="password" className="form-control mt-1"
                                               placeholder="Confirm password"
                                               value={confirmPassword}
                                               onChange={(event) => setConfirmPassword(event.target.value)} onClick={clearError}/>
                                    </div>
                                    <div className="text-center">
                                        <button className="btn btn-primary" type="submit">Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}